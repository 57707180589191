import React from "react";

import "./index.css";

const LoadingAnswer = () => {
  return (
    <div className="loading-answer">
      <div className="loading-answer-1"></div>
      <div className="loading-answer-2"></div>
      <div className="loading-answer-3"></div>
    </div>
  );
};

export default LoadingAnswer;
