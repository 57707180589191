import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout, Menu, Breadcrumb } from 'antd';

import ChatBot from './components/ChatBot';

const { Header, Content, Footer } = Layout;

function App() {
  return (
    <Layout className="layout">
      <Content style={{ padding: '0 0px' }}>
        <Router>
          <Routes>
            <Route path="/" exact component={<p>Homepage</p>} />
            <Route path="/:id" element={<ChatBot />} />
          </Routes>
        </Router>
      </Content>
    </Layout>
  );
}

export default App;