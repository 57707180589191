import React from "react";

const SendIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 24 24"
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2z"></path>
    </svg>
  );
};

export default SendIcon;
